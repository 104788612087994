.detail__leg {
    display: flex;
    flex-direction: row;
}

.detail__authority {
    color: #656782;
    font-size: 0.875rem;
}

.detail__time {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.detail__travel-leg {
    margin: 0 0.5rem;
}

.detail__leg__line {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.detail__front-text {
    margin-left: 0.375rem;
}

.detail__date {
    margin: 0.5rem 0;
}
