.feedback {
    display: flex;
}

.feedback__column {
    min-width: 30rem;
    margin-right: 2rem;
}

.feedback__column-offset {
    display: flex;
    padding-top: 1.25rem;
}

.feedback__form {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
}

.feedback__button {
    margin: 1.25rem 0 0 1.25rem;
}

.feedback__message {
    margin: 2rem 0;
}

.feedback__message-text {
    padding-bottom: 0.5rem;
    border-bottom: 1px solid #eee;
}

.feedback__params {
    margin: 3rem 0 2rem;
    border-top: 1px solid #ddd;
}

.feedback__arrival {
    display: flex;
    align-items: flex-start;
}
.feedback__clock {
    margin-right: 0.25rem;
}

.feedback__tags {
    margin: 1rem 0;
}

.feedback__tag {
    margin-right: 0.5rem;
}

.feedback__category {
    margin-top: 0.5rem;
}

.feedback__category strong {
    min-width: 5rem;
    display: inline-block;
}

.feedback__travel-header {
    margin: 1rem 0;
}

.feedback__filters {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
}

@media (max-width: 800px) {
    .feedback {
        flex-direction: column;
        margin: 0;
    }

    .feedback__column {
        min-width: 0;
        margin-right: 0;
    }

    .feedback__filters {
        grid-template-columns: repeat(2, 1fr);
    }
}
