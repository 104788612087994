@import '~@entur/alert/dist/styles.css';
@import '~@entur/button/dist/styles.css';
@import '~@entur/dropdown/dist/styles.css';
@import '~@entur/expand/dist/styles.css';
@import '~@entur/form/dist/styles.css';
@import '~@entur/icons/dist/styles.css';
@import '~@entur/layout/dist/styles.css';
@import '~@entur/loader/dist/styles.css';
@import '~@entur/tokens/dist/styles.css';
@import '~@entur/travel/dist/styles.css';
@import '~@entur/typography/dist/styles.css';
@import '~@entur/menu/dist/styles.css';
@import '~@entur/tooltip/dist/styles.css';

body {
    margin: 0;
}

ul {
    padding: 0;
    list-style-type: none;
}
