.result__trip-pattern {
    border-radius: var(--border-radiuses-default);
    box-shadow: var(--shadows-card-shadow);
    padding: 1rem;
    margin-bottom: 1rem;
}

.result__trip-pattern-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
}

.result__legs {
    display: flex;
    flex-direction: row;
}

.result__leg {
    margin-left: 1rem;
}

.result__leg:first-child {
    margin-left: 0;
}

.result__leg-line {
    margin-top: 0.5rem;
}
