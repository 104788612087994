.app {
    display: flex;
    flex-direction: row;
}

.page {
    padding: 2rem;
}

.side-menu {
    padding: 2rem;
    min-height: 100vh;
    min-width: 18rem;
    max-width: 18rem;
}

nav {
    position: absolute;
    right: 0;
    top: 0;
}

.eds-leg-line--horizontal {
    min-width: 0;
}

@media (max-width: 800px) {
    .page {
        padding: 1rem;
    }
}
